import React, { useState } from 'react'
import Layout from '../components/Layout'
import Lift from '../components/TheLift'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import '../styles/styles.scss'
import Arrow from '../images/icons/down-chevron.svg'

const theLift = () => {
  const image = useStaticQuery(graphql`
    query {
      thelift: file(relativePath: { eq: "thelift3dbook.png" }) {
        childImageSharp {
          fixed(width: 270) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  // const [clickState, setClickState] = useState(false)
  // const [clickStateTwo, setClickStateTwo] = useState(false)

  return (
    <Layout>
      <Lift />
      <div className="book-container">
        <div className="books-book">
          <div className="books">
            <Img fixed={image.thelift.childImageSharp.fixed} />
          </div>
        </div>
        <div className="synopsis">
          <div className="text-synopsis">
            <p>
              She went from riding the Tube to traveling the Milky Way. But when
              an alien admirer sets his sights on her, London might pay the
              ultimate price.
            </p>
            <p>
              Polly Granger’s life changed the instant she stepped into a London
              lift. Transported to another realm with two strange men as fellow
              passengers, she accidentally launches a successful and lucrative
              career as a dimension-hopping monster slayer. But after five
              years, a relaxing visit to her luxury Bay Area condo ends abruptly
              when she sees news footage of a twenty-foot-tall extraterrestrial
              rampaging through British streets… crying her name.
            </p>
            <p>
              Shocked when she recognizes the otherworldly creature, she’s
              somewhat dismayed to discover its carnage is all in a declaration
              of love. For her. And now she’d better strap on her weapons before
              the UK Special Forces decide giving her up is the only option to
              save them all…
            </p>
          </div>
          <div className="button-wrap-around">
            <div className="button-wrap">
              <a
                href="https://geni.us/3yiNmYI"
                target="_blank"
                rel="noreferrer"
              >
                EBOOK
              </a>
              <a href="https://geni.us/Ejgo" target="_blank" rel="noreferrer">
                PAPERBACK
              </a>
              <a
                href="https://geni.us/3BgsmdB"
                target="_blank"
                rel="noreferrer"
              >
                AUDIOBOOK
              </a>
            </div>
          </div>
          {/* <div className="button-wrap-around">
            <div className="button-wrap">
              <button
                onClick={() => {
                  setClickState(!clickState)
                }}
              >
                EBOOK{' '}
                <img
                  style={{
                    width: '20px',
                    marginLeft: '10px',
                    transform: clickState ? 'rotate(-180deg)' : 'rotate(-0deg)',
                    transition: '200ms',
                  }}
                  src={Arrow}
                  alt="arrow icon indicating, that if the button is pressed it will reveal more buttons"
                />
              </button>
              <button
                onClick={() => {
                  setClickStateTwo(!clickStateTwo)
                }}
              >
                PAPERBACK
                <img
                  style={{
                    width: '20px',
                    marginLeft: '10px',
                    transform: clickStateTwo
                      ? 'rotate(-180deg)'
                      : 'rotate(-0deg)',
                    transition: '200ms',
                  }}
                  src={Arrow}
                  alt="arrow icon indicating, that if the button is pressed it will reveal more buttons"
                />
              </button>
            </div>
            {clickState ? (
              <div className="button-wrap-amazon">
                <a
                  href="https://www.amazon.com/dp/B08DV4CRMC/ref=as_li_ss_tl?dchild=1&keywords=richard+weale&qid=1596040508&sr=8-2&linkCode=ll1&tag=richardweale-20&linkId=1785a478008b67f980d02391844fe314"
                  target="_blank"
                >
                  AMAZON.COM
                </a>
                <a
                  href="https://www.amazon.co.uk/dp/B08DV4CRMC/ref=as_li_ss_tl?dchild=1&keywords=richard+weale&qid=1596041559&sr=8-2&linkCode=ll1&tag=richardweale-21&linkId=31feb83d9ac876adfd8c01070145964d&language=en_US"
                  target="_blank"
                >
                  AMAZON.CO.UK
                </a>
              </div>
            ) : null}
            {clickStateTwo ? (
              <div className="button-wrap-amazon-paper">
                <a
                  href="https://www.amazon.com/Apprentice-Ann-Thrope-Book-2/dp/B08QBMJK1Z/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=&sr="
                  target="_blank"
                >
                  AMAZON.COM
                </a>
                <a
                  href="https://www.amazon.co.uk/Apprentice-Ann-Thrope-Book-2/dp/B08QBMJK1Z/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=1621507609&sr=8-1"
                  target="_blank"
                >
                  AMAZON.CO.UK
                </a>
              </div>
            ) : null}
          </div> */}
        </div>
      </div>
    </Layout>
  )
}

export default theLift
